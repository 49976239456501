import request from '@/utils/request'

/**
 * 公共下拉
 */
/**
 *
 * @param {公共} data
 */

// 商品实时库存数
export function depotNumFun(data) {
  return request({
    url: '/purchaseAdmin/return/goods-depot',
    method: 'post',
    data,
  })
}

// 经办人列表
export function jbrSelect(data) {
  return request({
    url: '/purchaseAdmin/base/operator-list',
    method: 'post',
    data,
  })
}
// 盘点人下拉

export function pdrSelect(data) {
  return request({
    url: '/baseAdmin/common/user-rel',
    method: 'post',
    data,
  })
}
// 采购退货单状态列表
export function statusSelect(data) {
  return request({
    url: '/purchaseAdmin/return/status-list',
    method: 'post',
    data,
  })
}
// 供应商列表
export function gysSelect(data) {
  return request({
    url: '/purchaseAdmin/base/supp-list',
    method: 'post',
    data,
  })
}

// 付款账户列表
export function fkzhSelect(data) {
  return request({
    url: '/baseAdmin/common/account',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {采购订单} data
 */

// 采购订单详情
export function purchaseOrderDetail(data) {
  return request({
    url: '/purchaseAdmin/order/order-info',
    method: 'post',
    data,
  })
}
// 添加采购订单
export function addPurchaseOrder(data) {
  return request({
    url: '/purchaseAdmin/order/create',
    method: 'post',
    data,
  })
}

// 采购订单作废
export function purchaseDelete(data) {
  return request({
    url: '/purchaseAdmin/order/order-discard',
    method: 'post',
    data,
  })
}
// 审核/添加  并生成。。。
// 采购订单审核并生成采购入库单
export function purchaseReview(data) {
  return request({
    url: '/purchaseAdmin/order/order-check',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {采购单据数据} data
 */

// 表格数据
export function getOrderList(data) {
  return request({
    url: '/purchaseAdmin/order/index',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {*采购入库单} data
 */

// 后台-采购单列表
export function rkdList(data) {
  return request({
    url: '/purchaseAdmin/base/index',
    method: 'post',
    data,
  })
}

// 后台-添加采购单
export function addPurchaseInsetOrder(data) {
  return request({
    url: '/purchaseAdmin/base/create',
    method: 'post',
    data,
  })
}

// 后台-采购单详情
export function purchaseInsetDetail(data) {
  return request({
    url: '/purchaseAdmin/base/base-info',
    method: 'post',
    data,
  })
}

/**
 * @param {采购退货单}
 */

// 后台-采购退货单列表
export function purchaseReturnList(data) {
  return request({
    url: '/purchaseAdmin/return/index',
    method: 'post',
    data,
  })
}

// 后台-添加采购退货单
export function addPurchaseReturn(data) {
  return request({
    url: '/purchaseAdmin/return/create',
    method: 'post',
    data,
  })
}

// 后台-采购退货单详情
export function purchaseReturnDetail(data) {
  return request({
    url: '/purchaseAdmin/return/return-info',
    method: 'post',
    data,
  })
}

// 采购退货单审核
export function purchaseReturnSave(data) {
  return request({
    url: '/purchaseAdmin/return/check',
    method: 'post',
    data,
  })
}
/**
 * @采购入库单作废
 */

export function purchaseDelete2(data) {
  return request({
    url: '/purchaseAdmin/base/base-out',
    method: 'post',
    data,
  })
}
/**
 * @采购退货作废
 */
export function purchaseDelete3(data) {
  return request({
    url: '/purchaseAdmin/return/return-out',
    method: 'post',
    data,
  })
}
