import request from '@/utils/request'

// 后台盘点列表
export function getList(data) {
  return request({
    url: '/depotAdmin/check/index',
    method: 'post',
    data,
  })
}

// 后台盘点详情
export function getDetail(data) {
  return request({
    url: '/depotAdmin/check/detail',
    method: 'post',
    data,
  })
}

// 后台新建盘点开始盘点
export function startCheck(data) {
  return request({
    url: '/depotAdmin/check/create',
    method: 'post',
    data,
  })
}
// /depotAdmin/check/complete
// 盘点完成 提交盘点
export function completeList(data) {
  return request({
    url: '/depotAdmin/check/complete',
    method: 'post',
    data,
  })
}

// 后台新增或修改盘点商品
export function saveCheck(data) {
  return request({
    url: '/depotAdmin/check/goods',
    method: 'post',
    data,
  })
}

// 后台盘点作废
export function deleteRow(data) {
  return request({
    url: '/depotAdmin/check/out',
    method: 'post',
    data,
  })
}

// 后台盘点审核
export function review(data) {
  return request({
    url: '/depotAdmin/check/verify',
    method: 'post',
    data,
  })
}
